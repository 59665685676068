import { Box, Typography, Stack, Tabs, Tab, Skeleton } from '@mui/material';
import { Icon, Button, Divider, Filters, TextField, Spinner } from '@shopify/polaris';
import { FilterIcon, SearchIcon } from '@shopify/polaris-icons';
import { useState, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import TableSort from './table_sort';
import { isEmpty } from 'src/utils/type_check';

export const TableButton = (props) => (
  <Button
    size={'micro'}
    onClick={props.onClick}
  >
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        px: { xs: 1, md: 0 },
      }}
    >
      {props.children}
    </Box>
  </Button>
);

export default function TableFilter({ useTable, isLoading, onScan }) {
  const { t } = useTranslation();
  const [searchClicked, setSearchClicked] = useState(useTable?.showSearch ?? false);
  const [searched, setSearch] = useState('');
  const toggleSearchClicked = useCallback(
    () => setSearchClicked((searchClicked) => !searchClicked),
    [],
  );

  useEffect(() => {
    setTimeout(() => {
      useTable.onChangeSearchQuery(searched, false);
    }, 300);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searched]);

  const tabs = useTable.tabItems.map((item) => ({
    content: <Typography sx={{ pr: 1 }}>{item.label}</Typography>,
    onAction: item.onClick,
    badge: item.badge,
  }));
  const tabsList = () => (
    <Tabs
      value={useTable.tabIndex}
      onChange={useTable.onChangeTab}
      variant="scrollable"
      scrollButtons={'auto'}
      TabIndicatorProps={{ style: { backgroundColor: 'transparent' } }}
      allowScrollButtonsMobile
      sx={{
        alignItems: 'center',
        p: 'var(--p-space-200)',
        '.MuiTabs-flexContainer': { gap: 'var(--p-space-100)' },
      }}
    >
      {tabs.map((tab, index) => (
        <Tab
          key={index}
          label={tab.content}
          value={index}
          icon={tab.badge}
          iconPosition="end"
          onClick={tab.onAction}
          sx={{
            padding: 'var(--p-space-100) var(--p-space-200)',
            minHeight: 0,
            minWidth: 0,
            borderRadius: 'var(--p-border-radius-200)',
            '&:hover': {
              backgroundColor: 'action.hover',
            },
            '&.Mui-selected': {
              backgroundColor: 'action.selected',
              color: 'inherit',
            },
            '& p': {
              fontWeight: 'var(--p-font-weight-medium)',
              fontSize: 'var(--p-text-body-sm-font-size)',
            },
          }}
        />
      ))}
    </Tabs>
  );

  const sortAction = () => (
    <TableSort
      sortBy={useTable.sortBy}
      orderBy={useTable.orderBy}
      onChangeOrder={useTable.onChangeOrder}
      onChangeSort={useTable.onChangeSort}
      useOptions={useTable.useSortOptions}
    />
  );
  const actionList = () => (
    <Stack
      direction={'row'}
      alignItems={'center'}
      spacing={1}
    >
      {isLoading && (
        <Spinner
          accessibilityLabel="Spinner example"
          size="small"
        />
      )}
      {useTable.showSearchFilter && (
        <TableButton onClick={toggleSearchClicked}>
          <Icon source={SearchIcon} />
          <Icon source={FilterIcon} />
        </TableButton>
      )}
      {useTable.useSortOptions && sortAction()}
    </Stack>
  );

  const searchList = () => (
    <Stack
      direction={'row'}
      alignItems={'center'}
      spacing={1}
      sx={{ pr: 1 }}
    >
      <Box style={{ flexGrow: 1, padding: '0.5rem' }}>
        <div
          onKeyDown={(e) => {
            if (useTable.searchOnEnter) {
              if (e.key === 'Enter') {
                if (onScan) {
                  onScan(searched);
                  setSearch('');
                } else {
                  useTable.onChangeSearchQuery(useTable.searchQuery, true);
                }
              }
            }
          }}
        >
          <TextField
            id="table_search_query"
            value={searched}
            onChange={(e) => {
              setSearch(e);
            }}
            prefix={<Icon source={useTable.searchQueryIcon || SearchIcon} />}
            autoComplete="off"
            variant="borderless"
            size="slim"
            autoFocus={false}
            focused={isEmpty(useTable.tabItems)}
            placeholder={useTable.searching || t('common:searching')}
          />
        </div>
      </Box>
      {!isEmpty(useTable.tabItems) && (
        <Box style={{ paddingTop: '0.5rem', paddingBottom: '0.5rem', marginRight: 0 }}>
          <Button
            size="medium"
            variant="tertiary"
            onClick={toggleSearchClicked}
          >
            {t('common:cancel')}
          </Button>
        </Box>
      )}

      {useTable.useSortOptions && sortAction()}
    </Stack>
  );
  const filtersList = () => (
    <Filters
      filters={useTable.filters}
      appliedFilters={useTable.appliedFilters}
      onClearAll={useTable.onClearFilter}
      hideQueryField
    />
  );
  const tabsView = () => (
    <Box
      display={'flex'}
      sx={{
        alignItems: 'center',
        pr: 1,
      }}
      className={`table_tab_filter ${!searchClicked ? 'visible' : ''}`}
    >
      {tabsList()}
      <Box sx={{ flexGrow: 1, minWidth: '1.5rem' }} />
      {actionList()}
    </Box>
  );

  const searchView = () => (
    <Box
      className={`table_search_filter ${searchClicked ? 'visible' : ''} ${useTable.filters.length > 0 ? '' : 'no_tab'}`}
    >
      {searchList()}
      <Divider />
      {filtersList()}
    </Box>
  );

  if (isLoading && !useTable.isLoadedOnce) {
    return (
      <Skeleton
        variant="text"
        sx={{ ml: 1, p: 1, width: 150 }}
      />
    );
  }

  if (
    isEmpty(useTable.tabItems) &&
    !useTable.showSearchFilter &&
    !useTable.useSortOptions &&
    isEmpty(useTable.filters)
  ) {
    return null;
  }

  if (isEmpty(useTable.tabItems)) {
    if (isEmpty(useTable.data)) {
      if (isEmpty(useTable.searchQuery)) {
        return null;
      }
    }
    return searchView();
  }

  if (!useTable.showTabOnEmpty && isEmpty(useTable.data)) {
    return null;
  }

  return (
    <>
      {tabsView()}
      {searchView()}
    </>
  );
}
