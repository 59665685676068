import axiosInstance, {
  configMultiPartFormData,
  fetcher,
  noRevalidateOnlyStaleOptions,
  revalidateOnlyStaleOptions,
} from 'src/utils/axios';
import useSWR, { mutate } from 'swr';
import { ENDPOINTS } from './endpoints';
import { useMemo } from 'react';
import { getURL } from 'src/utils/url';
import _ from 'lodash';
import { isArray } from 'src/utils/type_check';
import { OSTATUS } from 'src/sections/order/order_table_misc';
import { fAPIDate } from 'src/utils/format_time';

var currentURL = '';

export function useGetOrders(params) {
  const URL = getURL(ENDPOINTS.order.get_list, params);
  currentURL = URL;

  const { data, isLoading, error, isValidating } = useSWR(URL, fetcher, revalidateOnlyStaleOptions);
  const memoizedValue = useMemo(
    () => ({
      searchResults: data?.result || {},
      searchLoading: isLoading,
      searchError: error,
      searchValidating: isValidating,
    }),
    [data?.result, error, isLoading, isValidating],
  );

  return memoizedValue;
}

export function useGetOrderSortList() {
  const URL = ENDPOINTS.order.sort_list;

  const { data, isLoading, error, isValidating } = useSWR(
    URL,
    fetcher,
    noRevalidateOnlyStaleOptions,
  );

  const memoizedValue = useMemo(
    () => ({
      searchResults: data?.result || {},
      searchLoading: isLoading,
      searchError: error,
      searchValidating: isValidating,
    }),
    [data?.result, error, isLoading, isValidating],
  );

  return memoizedValue;
}

export function useGetOrder(id) {
  const URL = ENDPOINTS.order.get(id);
  const { data, isLoading, error, isValidating } = useSWR(URL, fetcher, revalidateOnlyStaleOptions);

  const memoizedValue = useMemo(
    () => ({
      searchResults: data?.result || {},
      searchLoading: isLoading,
      searchError: error,
      searchValidating: isValidating,
    }),
    [data?.result, error, isLoading, isValidating],
  );

  return memoizedValue;
}

export async function postOrder(order) {
  var clone = _.cloneDeep(order);
  delete clone.product;
  delete clone.orderDt;
  clone.orderDt = fAPIDate(order.orderDt) ? fAPIDate(order.orderDt) : '';
  clone.shippingAddress.email = clone.shippingAddress.email ? clone.shippingAddress.email : null;
  clone.items = clone.items.map((x) => ({
    orderQuantity: x.orderQuantity,
    unitPrice: x.unitPrice,
    sku: x.sku,
  }));
  var ret = await axiosInstance.post(ENDPOINTS.order.post, clone);
  return ret.data;
}

export async function updateOrder(id, order) {
  var clone = _.cloneDeep(order);
  delete clone.product;
  delete clone.orderDt;
  clone.shippingAddress.email = clone.shippingAddress.email ? clone.shippingAddress.email : null;

  clone.orderDt = fAPIDate(order.orderDt) ? fAPIDate(order.orderDt) : '';
  if (clone.items) {
    clone.items = clone.items.map((x) => ({
      itemSequence: x.itemSequence,
      orderQuantity: x.orderQuantity,
      unitPrice: x.unitPrice,
      sku: x.sku,
    }));
  }
  var ret = await axiosInstance.put(ENDPOINTS.order.upsert(id), clone);
  mutate(ENDPOINTS.order.get(id), (d) => d, true);
  return ret.data;
}

export async function markShipment(id) {
  const ret = await axiosInstance.post(ENDPOINTS.order.mark_shipment, {
    orders: [{ orderId: id }],
  });
  if (ret.data?.isSuccess) mutate(ENDPOINTS.order.get(id), (d) => d, true);
  return ret.data;
}

export async function markShipments(ids) {
  if (!isArray(ids)) ids = [ids];

  const statuses = {
    readyToShip: 0,
    notReadyToShip: 0,
  };

  const params = {
    orders: ids.map((id) => ({ orderId: id })),
  };
  const ret = await axiosInstance.post(getURL(ENDPOINTS.order.mark_shipment), params);
  if (ret.data?.isSuccess) {
    mutate(currentURL, (d) => d, true);

    ret.data.result.forEach((order) => {
      if (order.orderStatus === OSTATUS.READYTOSHIP) {
        statuses.readyToShip += 1;
      } else if (order.orderStatus === OSTATUS.NOTREADYTOSHIP) {
        statuses.notReadyToShip += 1;
      }
    });
  }

  return statuses;
}

export async function deleteOrder(id) {
  var ret = await axiosInstance.delete(ENDPOINTS.order.delete(id));
  return ret.data;
}

export async function deleteOrders(ids) {
  if (!isArray(ids)) ids = [ids];
  var isSuccess = false;
  for (var i = 0; i < ids.length; i++) {
    var ret = await axiosInstance.delete(ENDPOINTS.order.delete(ids[i]));
    if (ret.data?.isSuccess) {
      isSuccess = true;
    }
  }
  if (isSuccess) mutate(currentURL, (d) => d, true);
}

export async function deleteOrderItem(orderId, itemIndex) {
  var ret = await axiosInstance.delete(ENDPOINTS.order.delete_item(orderId, itemIndex));
  return ret.data;
}

export async function importOrders(userId, excelFile) {
  const formData = new FormData();
  formData.append('excelFile', excelFile);
  formData.append('userId', userId);

  var ret = await axiosInstance.post(
    ENDPOINTS.order.post_import,
    formData,
    configMultiPartFormData,
  );
  mutate(currentURL, (d) => d, true);
  return ret.data;
}

export async function exportOrders(params) {
  const URL = getURL(ENDPOINTS.order.get_excel_list, params);
  const res = await axiosInstance.get(URL);
  return res.data?.result?.list || [];
}

export async function verifyOrderAddress(verificationData) {
  var clone = _.cloneDeep(verificationData);
  var ret = await axiosInstance.post(ENDPOINTS.order.verify_address, clone);
  return ret.data;
}

export async function updateOrderAddress(id, order) {
  var clone = _.cloneDeep(order);
  clone.isVerify = true;
  clone.email = clone.email ? clone.email : null;

  var ret = await axiosInstance.put(ENDPOINTS.order.modify_address(id), clone);
  mutate(ENDPOINTS.outbound.packing.get_single(id), (d) => d, true);
  return ret.data;
}

export async function orderAddressOMSNotify(data) {
  var clone = _.cloneDeep(data);
  var ret = await axiosInstance.post(ENDPOINTS.order.oms_address_fail, clone);
  return ret.data;
}

export async function orderAddressOMSNotifyBulk(datas) {
  if (!Array.isArray(datas)) datas = [datas];

  const statuses = {
    issued: 0,
    notIssued: 0,
  };

  const notifyBulk = datas.map(async (data) => {
    try {
      const ret = await axiosInstance.post(ENDPOINTS.order.oms_address_fail, data);
      if (ret?.data?.isSuccess) {
        statuses.issued += 1;
      } else {
        statuses.notIssued += 1;
      }
    } catch (error) {
      statuses.notIssued += 1;
    }
  });

  await Promise.all(notifyBulk);
  return statuses;
}
