export const ORDERSTATUS = [
  {
    codeValue: 'Draft',
    codeValueNameLocal: '대기',
    codeValueNameEnglish: 'Draft',
    displayOrder: 1,
    codeReference1: 'true',
    codeReference2: 'true',
    codeReference3: null,
    codeReference4: null,
    description: 'CodeReference1:추가,삭제,수정 가능여부, CodeReference2:shipment 요청 가능여부',
    isActive: true,
  },
  {
    codeValue: 'OnHold',
    codeValueNameLocal: '보류(상품미매핑)',
    codeValueNameEnglish: 'On Hold',
    displayOrder: 2,
    codeReference1: 'true',
    codeReference2: 'true',
    codeReference3: null,
    codeReference4: null,
    description: 'CodeReference1:추가,삭제,수정 가능여부, CodeReference2:shipment 요청 가능여부',
    isActive: true,
  },
  {
    codeValue: 'ReadyToShip',
    codeValueNameLocal: '출고지시',
    codeValueNameEnglish: 'Ready To Ship',
    displayOrder: 3,
    codeReference1: 'false',
    codeReference2: 'false',
    codeReference3: null,
    codeReference4: null,
    description: 'CodeReference1:추가,삭제,수정 가능여부, CodeReference2:shipment 요청 가능여부',
    isActive: true,
  },
  {
    codeValue: 'NotReadyToShip',
    codeValueNameLocal: '보류(재고부족)',
    codeValueNameEnglish: 'Not Ready To Ship',
    displayOrder: 4,
    codeReference1: 'true',
    codeReference2: 'true',
    codeReference3: null,
    codeReference4: null,
    description: 'CodeReference1:추가,삭제,수정 가능여부, CodeReference2:shipment 요청 가능여부',
    isActive: true,
  },
  {
    codeValue: 'Shipped',
    codeValueNameLocal: '출고완료',
    codeValueNameEnglish: 'Shipped',
    displayOrder: 5,
    codeReference1: 'false',
    codeReference2: 'false',
    codeReference3: null,
    codeReference4: null,
    description: 'CodeReference1:추가,삭제,수정 가능여부, CodeReference2:shipment 요청 가능여부',
    isActive: true,
  },
  {
    codeValue: 'Cancel',
    codeValueNameLocal: '취소',
    codeValueNameEnglish: 'Cancel',
    displayOrder: 6,
    codeReference1: 'false',
    codeReference2: 'false',
    codeReference3: null,
    codeReference4: null,
    description: 'CodeReference1:추가,삭제,수정 가능여부, CodeReference2:shipment 요청 가능여부',
    isActive: true,
  },
];
