import { fetcher, revalidateOnlyStaleOptions } from 'src/utils/axios';
import useSWR from 'swr';
import { ENDPOINTS } from './endpoints';
import { useMemo } from 'react';
import { getURL } from 'src/utils/url';

export function useGetChannels(params) {
  params = {
    ClientType: 'channel',
    ...params,
  };
  const URL = getURL(ENDPOINTS.client.get_list, params);
  const { data, isLoading, error, isValidating } = useSWR(URL, fetcher, revalidateOnlyStaleOptions);
  const memoizedValue = useMemo(
    () => ({
      searchResults: data?.result?.list || [],
      searchLoading: isLoading,
      searchError: error,
      searchValidating: isValidating,
    }),
    [data?.result, error, isLoading, isValidating],
  );

  return memoizedValue;
}
