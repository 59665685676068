import { Icon, Autocomplete } from '@shopify/polaris';
import { SearchIcon } from '@shopify/polaris-icons';
import { useMemo, useState } from 'react';
import { useDebounce } from 'src/hooks/use_debounce';
import { isEmpty } from 'src/utils/type_check';
import { cleanParams } from 'src/utils/url';

export const SingleSelectAutocomplete = ({
  label,
  useOptions,
  params,
  value,
  onSelect,
  searchParamName,
  toOption,
}) => {
  const [text, setText] = useState('');
  const debounceText = useDebounce(text);

  const debounceParams = useMemo(
    () => ({
      ...params,
      [searchParamName]: debounceText,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [debounceText],
  );

  const { searchResults } = useOptions(cleanParams(debounceParams));

  const options = useMemo(() => {
    return searchResults.map((r) => toOption(r));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchResults]);
  return (
    <Autocomplete
      options={options}
      selected={value}
      onSelect={(val) => {
        if (!isEmpty(val)) {
          var label = options.find((x) => x.value === val[0]).label;
          onSelect({ label: label, value: val[0] });
        }
      }}
      textField={
        <Autocomplete.TextField
          onChange={setText}
          label={label}
          value={text}
          prefix={
            <Icon
              source={SearchIcon}
              tone="base"
            />
          }
          placeholder="Search"
          autoComplete="off"
        />
      }
    />
  );
};
