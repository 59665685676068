import { Page, Button, Card, Tag, Divider, Text, InlineStack } from '@shopify/polaris';
import { Box, Typography, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useGetClientChannels, deleteClientUninstallClient } from 'src/api/mapping_api';
import { useState, useCallback, useEffect } from 'react';
import Loading from 'src/components/loading';
import ChannelInfoList from 'src/components/cards/channel_info_list';
import { useGetChannelSort } from 'src/api/mapping_api';
import { chooseLan } from 'src/utils/language_code';
import RegisterModal from 'src/sections/settings/channels/register_modal';
import TableSort from 'src/components/table/table_sort';
import UserAutocomplete from 'src/components/user_autocomplete';
import { XIcon } from '@shopify/polaris-icons';
import { toast } from 'react-toastify';
import ChannelForm from 'src/sections/settings/channels/channel_form';
import { print } from 'src/utils/log';
import { useGetMe } from 'src/api/user_api';
import { getUserName } from 'src/utils/format_data';
import { getURL } from 'src/utils/url';
import { mutate } from 'swr';
import { ENDPOINTS } from 'src/api/endpoints';
import EmptyStateCard from 'src/components/cards/empty_state_card';

const MODES = {
  VIEW: 'view',
  FORM: 'form',
};

export default function ChannelView() {
  const [mode, setMode] = useState(MODES.VIEW);
  const [channelData, setChannelData] = useState({});
  const { user, role: currentRole } = useGetMe();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const toggleRegisterModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const handleChannelInfoClick = (channel) => {
    setChannelData(channel);
    setMode(MODES.FORM);
  };

  const onFormSuccess = () => {
    setChannelData({});
    mutate(currentURL);
    setMode(MODES.VIEW);
  };

  const handleMode = () => {
    setChannelData({});
    setMode(mode === MODES.VIEW ? MODES.FORM : MODES.VIEW);
  };

  const [sortColumn, setSortColumn] = useState('Created');
  const [sortDirection, setSortDirection] = useState('Ascending');
  const [selectedUser, setSelectedUser] = useState(currentRole === 'customer' ? user : {});
  const handleSelectUser = (user) => {
    setSelectedUser(user);
  };

  const { t } = useTranslation(['settings', 'validation']);
  const [params, setParams] = useState({
    UserId: '',
    ClientType: 'channel',
    IsMapping: true,
    isActive: true,
  });

  useEffect(() => {
    if (selectedUser.userId) {
      setParams((prevParams) => ({
        ...prevParams,
        UserId: selectedUser.userId,
      }));
    }
  }, [selectedUser]);

  const {
    searchResults: channels = [],
    searchLoading: loading,
    searchError: error,
  } = useGetClientChannels(params);

  const currentURL = getURL(ENDPOINTS.mapping.get_channels, params);

  const handleSortColumnChange = useCallback((value) => {
    setParams((prevParams) => ({ ...prevParams, sortBy: value }));
    setSortColumn(value);
  }, []);

  const handleSortDirectionChange = useCallback((direction) => {
    setParams((prevParams) => ({ ...prevParams, orderBy: direction }));
    setSortDirection(direction);
  }, []);

  const uninstallChannel = async (userId, channel) => {
    try {
      const response = await deleteClientUninstallClient(
        userId,
        channel.clientId,
        channel.mappingCode,
      );
      if (!response.isSuccess) {
        toast.error(t('common:error'), '\n', response.errorMessages[0]);
      } else {
        mutate(currentURL);
      }
    } catch (error) {
      toast.error(t('common:error'), '\n', error.message);
    }
  };

  const renderChannels = () => {
    if (error) {
      return <div> {error}</div>;
    }
    if (loading) {
      return <Loading />;
    }
    if (channels.length === 0 || !selectedUser.userId) {
      return (
        <EmptyStateCard
          heading={t('settings:channels.no_channels')}
          body={t('settings:channels.channels_description')}
          image="/images/alertIcon.png"
          center
        >
          <Button
            variant={'secondary'}
            onClick={() => {
              if (!selectedUser.userId) {
                toast.error(t('validation:choose_customer_first'));
              } else {
                toggleRegisterModal();
              }
            }}
          >
            {t('settings:channels.register_channels')}
          </Button>
        </EmptyStateCard>
      );
    }
    return (
      <Card padding={0}>
        <Box
          display="flex"
          justifyContent="space-between"
          sx={{ padding: 'var(--p-space-300)' }}
        >
          <Tag size="large">
            <Text
              variant="bodySm"
              fontWeight="medium"
            >
              {t('settings:channels.installed')}
            </Text>
          </Tag>
          <TableSort
            useOptions={useGetChannelSort}
            sortBy={sortColumn}
            orderBy={sortDirection}
            onChangeOrder={handleSortDirectionChange}
            onChangeSort={handleSortColumnChange}
          />
        </Box>
        <Divider />
        <Box>
          {channels.map((channel, index) => (
            <Box
              key={channel.clientId}
              sx={{
                paddingInline: 'var(--p-space-400)',
                paddingTop: 'var(--p-space-400)',
                borderBottom: index !== channels.length - 1 ? '1px solid #e5e5e5' : 'none',
              }}
            >
              <ChannelInfoList
                avatar={channel.representativeImageUrl}
                name={chooseLan(channel.clientNameLocal, channel.clientNameEnglish)}
                description={channel.businessRegistrationNumber}
                onDeleteClick={() => uninstallChannel(selectedUser.userId, channel)}
                onViewClick={() => handleChannelInfoClick(channel)}
              />
            </Box>
          ))}
        </Box>
      </Card>
    );
  };

  const renderChannelPage = () => {
    return mode === MODES.VIEW ? (
      <>
        <Box sx={{ paddingBottom: 'var(--p-space-400)' }}>
          {currentRole === 'customer' ? null : Object.keys(selectedUser).length === 0 ? (
            <Card>
              <Box sx={{ paddingBottom: 'var(--p-space-200)' }}>
                <Typography variant="heading_sm">{t('common:field.customer')}</Typography>
              </Box>
              <UserAutocomplete onSelect={handleSelectUser} />
            </Card>
          ) : (
            <Card roundedAbove="sm">
              <InlineStack align="space-between">
                <Stack spacing={2}>
                  <Typography variant="heading_sm">{getUserName(selectedUser)}</Typography>
                  <Typography variant="body_md">{selectedUser.loginId}</Typography>
                </Stack>
                {currentRole !== 'customer' && (
                  <Button
                    icon={XIcon}
                    variant="plain"
                    tone="critical"
                    onClick={() => setSelectedUser({})}
                    accessibilityLabel="Edit"
                  />
                )}
              </InlineStack>
            </Card>
          )}
        </Box>
        {renderChannels()}
        <RegisterModal
          userId={selectedUser?.userId ?? ''}
          isOpen={isModalOpen}
          onClose={() => {
            toggleRegisterModal();
            mutate(currentURL);
          }}
          onCreate={handleMode}
          onViewClick={handleChannelInfoClick}
        />
      </>
    ) : (
      <ChannelForm
        onSuccess={onFormSuccess}
        userId={selectedUser.userId}
        clientId={channelData ? channelData.clientId : ''}
      />
    );
  };
  print('channel_view page');

  return (
    <Page
      fullWidth
      title={
        mode === MODES.VIEW
          ? t('settings:channels.title')
          : channelData && channelData.clientId
            ? chooseLan(channelData.clientNameLocal, channelData.clientNameEnglish)
            : t('settings:channels.new_channel')
      }
      backAction={mode === MODES.FORM ? { content: t('common:Back'), onAction: handleMode } : null}
      primaryAction={
        selectedUser.userId &&
        mode === MODES.VIEW && (
          <Button
            variant="primary"
            onClick={toggleRegisterModal}
          >
            {t('settings:channels.register_channels')}
          </Button>
        )
      }
    >
      {renderChannelPage()}
      <div className="bottom-padding"></div>
    </Page>
  );
}
