import { Box, Typography } from '@mui/material';
import { Badge, Button, IndexTable } from '@shopify/polaris';
import { useState } from 'react';
import { toast } from 'react-toastify';
import {
  deleteCourierFromLocation,
  deleteUserLocation,
  useGetLocationMapping,
} from 'src/api/mapping_api';
import { disconnectConfirm } from 'src/components/dialog/confirmation';
import Image from 'src/components/image/image';
import Table from 'src/components/table/table';
import useTable from 'src/hooks/use_table';
import i18n from 'src/locales/i18n';
import { chooseLan } from 'src/utils/language_code';
import { print } from 'src/utils/log';
import { mapLocationToCourierModal } from '../location/locations/connect_location_to_courier_modal';

const TYPE = {
  user: 'user',
  courier: 'courier',
};

const headers = (type) => {
  var name = { title: i18n.t('common:field.name') };
  var role = { title: i18n.t('common:field.role') };
  var country = { title: i18n.t('common:field.country') };
  var email = { title: i18n.t('common:field.email') };
  if (type === TYPE.user) return [name, role, country, email];
  if (type === TYPE.courier) return [name, { title: '' }];
  return [];
};

const bulkAction = (data, selectedIds, handleSelectionChange, locationId) => {
  return [
    {
      content: i18n.t('common:disconnect'),
      destructive: true,
      onAction: async () => {
        const confirmation = await disconnectConfirm();
        if (confirmation) {
          var res = await deleteUserLocation(selectedIds, locationId);
          if (res.isSuccess) {
          } else {
            toast.error(res.errorMessages[0]);
          }
        }
      },
    },
  ];
};

const rowMarkup = ({ data, selectedItems }, type, locationId) => {
  return data.map((user, index) => {
    var {
      userId,
      userNameLocal,
      userNameEnglish,
      countryCode,
      loginId,
      roleNameLocal,
      roleNameEnglish,
      courierId,
      representativeImageUrl,
      courierNameLocal,
      courierNameEnglish,
      isMapping,
    } = user;
    var id = type === TYPE.user ? userId : courierId;
    var name =
      type === TYPE.user
        ? chooseLan(userNameLocal, userNameEnglish)
        : chooseLan(courierNameLocal, courierNameEnglish);
    var width = type === TYPE.user ? '25%' : '50%';
    return (
      <IndexTable.Row
        id={id}
        key={id}
        selected={selectedItems.includes(id)}
        position={index}
        onClick={() => {}}
      >
        {type === TYPE.user && (
          <>
            <Box
              component={IndexTable.Cell}
              sx={{ width: width }}
            >
              {name}
            </Box>
            <Box
              component={IndexTable.Cell}
              sx={{ width: width }}
            >
              {chooseLan(roleNameLocal, roleNameEnglish)}
            </Box>
            <Box
              component={IndexTable.Cell}
              sx={{ width: width }}
            >
              {countryCode}
            </Box>
            <Box
              component={IndexTable.Cell}
              sx={{ width: width }}
            >
              {loginId}
            </Box>
          </>
        )}
        {type === TYPE.courier && (
          <>
            <Box
              component={IndexTable.Cell}
              sx={{ width: width, display: 'flex', alignItems: 'center' }}
            >
              <Box sx={{ pr: 1.5 }}>
                <Image
                  src={representativeImageUrl}
                  sx={{
                    width: 40,
                    height: 40,
                    borderRadius: 'var(--p-border-radius-200)',
                  }}
                />
              </Box>

              <div
                className="black-button"
                onClick={async () => {
                  await mapLocationToCourierModal({ selectedLocation: locationId, courierId });
                }}
              >
                <Typography variant="body_md_medium">{name}</Typography>
              </div>
            </Box>
            <Box
              component={IndexTable.Cell}
              sx={{ width: width }}
            >
              <Button
                variant="secondary"
                size="medium"
                tone={isMapping ? 'critical' : ''}
                onClick={async () => {
                  if (!isMapping) {
                    try {
                      await mapLocationToCourierModal({ selectedLocation: locationId, courierId });
                    } catch (error) {
                      toast.error(error);
                    }
                  } else {
                    try {
                      const response = await deleteCourierFromLocation(locationId, courierId);
                      if (response.isSuccess) {
                      } else {
                        throw Error(response.errorMessages[0]);
                      }
                    } catch (error) {
                      toast.error(error);
                    }
                  }
                }}
              >
                {!isMapping ? i18n.t('common:connect') : i18n.t('common:disconnect')}
              </Button>
            </Box>
          </>
        )}
      </IndexTable.Row>
    );
  });
};

const tabs = (count) => {
  var user = {
    label: i18n.t('common:field.user'),
    value: TYPE.user,
    onClick: () => {},
    badge: <Badge>{count.user}</Badge>,
  };
  var courier = {
    label: i18n.t('common:field.courier'),
    value: TYPE.courier,
    onClick: () => {},
    badge: <Badge>{count.courier}</Badge>,
  };
  return [user, courier];
};

const MappingTableView = ({ selectedLocation }) => {
  const { courierList, userList, searchCount, searchLoading } =
    useGetLocationMapping(selectedLocation);
  const [type, setType] = useState(TYPE.user);

  const getData = () => {
    if (type === TYPE.user) return userList;
    if (type === TYPE.courier) return courierList;
    return [];
  };

  const table = useTable({
    data: getData() ?? [],
    headers: headers(type),
    tabItems: tabs(searchCount ?? {}),
    defaultTab: type,
    rowMarkup: (props) => rowMarkup(props, type, selectedLocation),
    showRowsPerPage: false,
    showPagination: false,
    showSearchFilter: false,
    bulkAction: (data, selectedIds, handleSelectionChange) =>
      bulkAction(data, selectedIds, handleSelectionChange, selectedLocation),
    selectable: type === TYPE.user ? true : false,
    resourceIdResolver: (d) => d.userId,
  });

  if (table.isRefetch.value) {
    setType(table.tabItems[table.tabIndex].value);
    table.onRefetched();
  }

  print('users location table load');

  return (
    <Table
      useTable={table}
      isLoading={searchLoading}
    />
  );
};

export default MappingTableView;
